import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import CardVehicle from '../CardVehicle'
import { createIntersectionObserver } from '../../utils/carousel'
import initLazyLoading from '../../utils/lazyLoading'
import { ArrowLeft, ArrowRight } from 'lucide-react'
import { SwiperButton } from '../SwiperSlideButtons'

const Carousel = (props) => {
  const { items, unlocked_user } = props

  return (
    <div className="carousel-container">
      <SwiperButton id="carousel-prev-button">
        <ArrowLeft strokeWidth={2} />
      </SwiperButton>
      <Swiper
        centerInsufficientSlides={true}
        centeredSlides={items.length > 4}
        className="swiper-container"
        grabCursor={true}
        initialSlide={Math.floor(items.length / 2)}
        loop={items.length > 10}
        modules={[Navigation]}
        navigation={{
          disabledClass: 'swiper-button--disabled',
          nextEl: '#carousel-next-button',
          prevEl: '#carousel-prev-button',
        }}
        on={{
          init() {
            initLazyLoading()
            if (isMobile) return
            createIntersectionObserver({
              root: document.body,
              items: this.slides,
              className: 'carousel__item-slide--out',
            })
          },
        }}
        preventClicks={true}
        slidesPerView="auto"
        spaceBetween={16}
        speed={200}
      >
        {items.map((vehicle, index) => {
          return (
            <SwiperSlide
              className="swiper-slide-container"
              key={`${index}-swiper-slide-item`}
            >
              <CardVehicle
                {...vehicle}
                unlocked_user={unlocked_user}
                className="carousel__item"
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
      <SwiperButton id="carousel-next-button">
        <ArrowRight strokeWidth={2} />
      </SwiperButton>
    </div>
  )
}

Carousel.defaultProps = {
  items: [],
}

Carousel.propTypes = {
  items: PropTypes.array,
}

export default Carousel
