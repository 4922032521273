import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const IconPremium = ({ title }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip className="card-vehicle__tooltip card-vehicle__tooltip--premium">{title}</Tooltip>}
    >
      <i className="icon icon-premium card-vehicle__icon card-vehicle__icon--premium" />
    </OverlayTrigger>
  )
}

export default IconPremium
