import React from 'react'
import styled, { keyframes } from 'styled-components'

const Loading = () => {
  return <Loader />
}

const pulsing = keyframes`
    50% {background-color: #F8F8F9}
    100% {background-color: #E8EBEE}
`

const Loader = styled.div`
  aspect-ratio: 1;
  background-color: #e8ebee;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  animation: ${pulsing} 1.6s linear infinite;
  @media (min-width: 560px) {
    aspect-ratio: 2.67;
  }
`

export default Loading
