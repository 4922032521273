import React from 'react'
import ReactDOM from 'react-dom'
import isMobile from 'is-mobile'
import { Provider } from 'react-redux'

import store from '../store'
import FilterMainApp from '../components/FilterMainApp'
import Carousel from '../components/CarouselApp'
import AdvantagesSectionCarousel from '../components/AdvantagesSectionCarousel'
import StoresCarouselApp from '../components/StoresCarouselApp'
import AdvantageCards from '../components/AdvantageCardsCarousel'
import { applyPlateMask } from '../utils/applyMasks'
import validationFactory from '../utils/validation'
import Loading from '../components/Banner/loader'

const elFilterMain = document.getElementById('render-filter-main')
const elDataBannerMain = document.getElementById('banner__showcase')
const elDataBannerFooter = document.getElementById('banner__footer')

const elDataCarouselFeatured = document.getElementById(
  'carousel-offers-featured'
)
const elDataCarouselOther = document.getElementById('carousel-offers-other')
const elOurStoresCarousel = document.getElementById('our-stores-carousel')
const elDataOurStoresCarousel = document.getElementById(
  'data-our-stores-carousel'
)
const elDataAdvantagesSection = document.querySelector('.section-advantages')
const autoavaliarIndex = document.querySelector('.render-sell-your-vehicle')
const elAdvantagesCarousel = document.getElementById('advantage-cards-carousel')
const validator = validationFactory('#form-identify-your-vehicle')

if (elFilterMain) {
  const redirect = elFilterMain.dataset.redirect,
    redirectAdvance = elFilterMain.dataset.redirectAdvance

  ReactDOM.render(
    <FilterMainApp redirect={redirect} redirectAdvance={redirectAdvance} />,
    document.getElementById('render-filter-main')
  )
}

if (elDataBannerMain) {
  const Banner = React.lazy(() => import('../components/Banner'))
  const { items } = elDataBannerMain.dataset

  ReactDOM.render(
    <React.Suspense fallback={<Loading />}>
      <Banner items={JSON.parse(items)} />
    </React.Suspense>,
    document.getElementById('banner__showcase')
  )
}

if (elDataBannerFooter) {
  const Banner = React.lazy(() => import('../components/Banner'))
  const { items } = elDataBannerFooter.dataset

  ReactDOM.render(
    <React.Suspense fallback={<Loading />}>
      <Banner ratio={3.84} items={JSON.parse(items)} />
    </React.Suspense>,
    elDataBannerFooter
  )
}

if (elDataCarouselFeatured) {
  const { items, unlocked_user } = elDataCarouselFeatured.dataset

  ReactDOM.render(
    <Carousel items={JSON.parse(items)} unlocked_user={unlocked_user} />,
    document.getElementById('carousel-offers-featured')
  )
}

if (autoavaliarIndex) {
  const SellYourVehicle = React.lazy(() =>
    import('../components/SellYourVehicle')
  )
  const JSONoptions = JSON.parse(autoavaliarIndex.dataset.options)
  const image = autoavaliarIndex.dataset.image
  const buy_your_vehicle_sales_index_path =
    autoavaliarIndex.dataset.buy_your_vehicle_sales_index_path
  const usedAvaliation = autoavaliarIndex.dataset.usedAvaliation

  ReactDOM.render(
    <Provider store={store}>
      <React.Suspense fallback={<Loading />}>
        <SellYourVehicle
          options={JSONoptions}
          image={image}
          buy_your_vehicle_sales_index_path={buy_your_vehicle_sales_index_path}
          usedAvaliation={usedAvaliation}
        />
      </React.Suspense>
    </Provider>,
    document.querySelector('.render-sell-your-vehicle')
  )
  applyPlateMask(autoavaliarIndex)
}

if (elDataCarouselOther) {
  const { items, unlocked_user } = elDataCarouselOther.dataset

  ReactDOM.render(
    <Carousel items={JSON.parse(items)} unlockedUser={unlocked_user} />,
    document.getElementById('carousel-offers-other')
  )
}

if (elOurStoresCarousel) {
  const { all_stores_sanitize, url_state } = elDataOurStoresCarousel.dataset

  ReactDOM.render(
    <StoresCarouselApp
      elements={JSON.parse(all_stores_sanitize)}
      urlState={url_state}
    />,
    elOurStoresCarousel
  )
}

if (elAdvantagesCarousel) {
  ReactDOM.render(
    <AdvantageCards {...elAdvantagesCarousel.dataset} />,
    document.getElementById('advantage-cards-carousel')
  )
}

const buyingAdvantages = [
  {
    id: 'b1',
    number: '01',
    title: 'Escolha seu veículo',
    description: 'Escolha a melhor opção com garantia exclusiva. ',
  },
  {
    id: 'b2',
    number: '02',
    title: 'Faça a reserva',
    description:
      'Com um valor simbólico, você pode reservar qualquer veículo disponível.',
  },
  {
    id: 'b3',
    number: '03',
    title: 'Como você vai comprar?',
    description:
      'Confira todas as possibilidades de pagamento e escolha a melhor para você.',
  },
]

const buyingAdvantagesExtra = {
  id: 'b4',
  number: '04',
  title: 'Você tem total controle',
  description:
    'Oferecemos uma experiência integrada entre o fisíco e o digital, e você decide onde fazer cada etapa da compra.',
}

const buyingAdvantagesOnlineExtra = {
  id: 'b4',
  number: '04',
  title: 'Receba seu veículo',
  description: 'Combine a retirada do seu veículo com o consultor online.',
}

const sellingAdvatages = [
  {
    id: 's1',
    number: '01',
    title: 'Receba uma oferta',
    description:
      'Insira alguns detalhes do seu veículo e receba uma oferta real em segundos.',
  },
  {
    id: 's2',
    number: '02',
    title: 'Agende sua inspeção',
    description: 'Agende uma inspeção num dos nossos centros especializados.',
  },
  {
    id: 's3',
    number: '03',
    title: 'Seja pago imediatamente',
    description:
      'Se estiver tudo ok na inspeção você recebe o pagamento na hora.',
  },
]

const handleRenderCarouselByState = (state) => {
  const caroulselEl = document.getElementById('advantages-section-carousel')
  const onlyOnlineBuying = caroulselEl.dataset.buyOnline

  ReactDOM.render(
    <AdvantagesSectionCarousel
      items={
        state === 'buy'
          ? onlyOnlineBuying == 'true'
            ? [...buyingAdvantages, buyingAdvantagesOnlineExtra]
            : [...buyingAdvantages, buyingAdvantagesExtra]
          : sellingAdvatages
      }
    />,
    caroulselEl
  )

  if (state === 'buy') window.dispatchEvent(new Event('resize'))

  const sellCallToActionButton = document.querySelector(
    '.section-advantages_call-to-action-sell'
  )
  const buyCallToActionButton = document.querySelector(
    '.section-advantages_call-to-action-buy'
  )
  const selectedButtonBuy = document.querySelector(
    '.section-advantages__button-buy'
  )
  const selectedButtonSell = document.querySelector(
    '.section-advantages__button-sell'
  )
  if (state === 'buy') {
    sellCallToActionButton.style.display = 'none'
    buyCallToActionButton.style.display = 'initial'
    selectedButtonBuy.classList.add('selected_button')
    if (selectedButtonSell) {
      selectedButtonSell.classList.remove('selected_button')
    }
  } else if (state === 'sell') {
    buyCallToActionButton.style.display = 'none'
    sellCallToActionButton.style.display = 'initial'
    selectedButtonBuy.classList.remove('selected_button')
    if (selectedButtonSell) {
      selectedButtonSell.classList.add('selected_button')
    }
  }
}

if (elDataAdvantagesSection) {
  const buyButton = document.querySelector('.section-advantages__button-buy')
  if (buyButton) {
    buyButton.addEventListener('click', () =>
      handleRenderCarouselByState('buy', false)
    )
  }
  const sellButton = document.querySelector('.section-advantages__button-sell')
  if (sellButton) {
    sellButton.addEventListener('click', () =>
      handleRenderCarouselByState('sell', false)
    )
  }
  handleRenderCarouselByState('buy')
  //O primeiro render injetar classes diferentes quebra o carousel por algum motivo
  handleRenderCarouselByState('buy', false)
}

/**
 * Validação do form
 */
function handleValidationForm() {
  const elForm = document.querySelector('#form-identify-your-vehicle')

  if (!elForm) return

  elForm.addEventListener('submit', (e) => {
    const formIsValid = validator.validateAll(elForm).length === 0

    if (!formIsValid) {
      e.preventDefault()
      return
    }

    elForm.submit()
  })
}

handleValidationForm()
