import React from 'react'
import Carousel from '../Carousel'

const CarouselApp = (props) => {
  const { unlocked_user } = props

  return <Carousel unlocked_user={unlocked_user} {...props} />
}

export default CarouselApp
