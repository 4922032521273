const images = require.context('../images', true)
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'url-search-params-polyfill'
import 'intersection-observer'
import '../config/fonts'
// configurando css importado na home
import '../stylesheets/home-first-fold.scss'

import '../src/renders/footer'
import '../src/renders/header'
import '../src/renders/home'

import applyEmbed from '../src/utils/embed'
import { createCookieUTM } from '../src/utils/utmUtils'
window.onload = function () {
  applyEmbed('.with-embed-responsive')
  lucide.createIcons()
  createCookieUTM()
}
