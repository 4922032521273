import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-bootstrap/Spinner'

const FilterMain = (props) => {
  const {
    isLoading,
    textSearch,
    onTextSearchChange,
    onSubmit,
  } = props

return (
    <form className="filter-main" onSubmit={onSubmit}>
        <input
            className="filter-main__search-input"
            type="text"
            placeholder='Qual veículo você procura?'
            value={textSearch}
            onChange={onTextSearchChange}
        />

        <button
          type="submit"
          className="btn btn-primary filter-main__btn-search"
          onClick={onSubmit}
          disabled={isLoading}
        >
          { isLoading && <Spinner animation="border" size="sm" />}
          {
              !isLoading && <>Buscar ofertas</>
          }
        </button>
    </form>
  )
}

FilterMain.defaultProps = {
  isLoading: false,
}

FilterMain.propTypes = {
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  onTextSearchChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default FilterMain
