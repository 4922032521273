export const defaultCardsArray = ({
  dealer_has_financing_active,
  advanced_search_path,
  buy_your_vehicle_sales_index_path,
}) => {
  const cardsArray = [
    {
      title: 'Reserve e compre online',
      message:
        'Reserve seu veículo online e tenha total segurança durante seu processo de compra.',
      linkTitle: 'Veja nosso catálogo',
      linkAddress: advanced_search_path,
    },
    {
      title: 'Venda seu veículo',
      message:
        'Insira alguns detalhes do seu veículo e receba uma oferta real em segundos.',
      linkTitle: 'Receba uma oferta',
      linkAddress: buy_your_vehicle_sales_index_path,
    },
  ]

  // Card de Financiamento
  dealer_has_financing_active === 'true' &&
    cardsArray.push(financingCard(advanced_search_path))

  return cardsArray
}

const financingCard = (advanced_search_path) => ({
  title: 'Simule um financiamento',
  message:
    'Te ajudamos a encontrar um financiamento com parcelas que cabem no seu bolso.',
  linkTitle: 'Veja nosso catálogo',
  linkAddress: advanced_search_path,
})
