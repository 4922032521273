import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { AdvantageCardsCarouselProps, Breakpoint } from './types'
import { Card } from './card'
import { defaultCardsArray } from './cardsData'

const AdvantageCardsCarousel = ({
  dealer_has_financing_active,
  buy_your_vehicle_sales_index_path,
  advanced_search_path,
}: AdvantageCardsCarouselProps) => {
  const cardsArray = defaultCardsArray({
    dealer_has_financing_active,
    buy_your_vehicle_sales_index_path,
    advanced_search_path,
  })

  const breakpoint: Breakpoint = {
    0: {
      slidesPerView: 'auto',
      spaceBetween: 16,
    },
    560.5: {
      slidesPerView: 'auto',
      spaceBetween: 24,
    },
    745: {
      slidesPerView: 3,
      spaceBetween: 32,
    },
  }

  return (
    <>
      <Swiper
        breakpoints={breakpoint}
        centerInsufficientSlides={true}
        className="swiper-container"
        grabCursor={true}
        preventClicks={true}
        spaceBetween={16}
        speed={200}
      >
        {cardsArray.map((card, index) => {          
          return (
            <SwiperSlide className="swiper-slide-container" key={index}>
              <Card
                {...card}
                className={cardsArray.length <= 2 && 'advantage-card--col-6'}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  )
}

export default AdvantageCardsCarousel
