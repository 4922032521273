/**
 * Aplica uma class aos elementos fora de vista
 * 
 * @param {Object} root elemento root option de IntersectionObserver
 * @param {Object} items elementos a seres observados
 * @param {Object} className nome da class a ser adicionado aos elementos fora de vista
 */
export function createIntersectionObserver({ root, items, className }) {
  const callbackIntersection = (entries) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio < 1) {
        entry.target.classList.add(className)
      } else {
        entry.target.classList.remove(className)
      }
    })
  }

  const optionsIntersection = {
    root,
    threshold: 1,
  }

  let observer = new IntersectionObserver(callbackIntersection, optionsIntersection)

  Array.prototype.forEach.call(items, (item) => {
    observer.observe(item)
  })
}
