import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const Footer = (props) => {
  const { slug, url, unlock, unlocked_user, price, purchase_price } = props

  const vehicle_locked =
    unlock && !unlocked_user && purchase_price && purchase_price !== price

  const Price = () => {
    if (vehicle_locked) {
      return (
        <div className="card-vehicle-price-container">
          <span className="card-vehicle__price-from">De</span>
          <span className="card-vehicle__price-value card-vehicle__price-value--line-through">
            {price}
          </span>
        </div>
      )
    }

    const discount_unlocked =
      unlock && unlocked_user && purchase_price && purchase_price !== price

    if (discount_unlocked) {
      return (
        <div className="card-vehicle-price-container">
          <span className="card-vehicle__price-from">
            De{' '}
            <span className="card-vehicle__price-from card-vehicle__price-from--line-through">
              {price}
            </span>{' '}
            por
          </span>
          <span className="card-vehicle__price-value">{purchase_price}</span>
        </div>
      )
    }

    return (
      <div className="card-vehicle-price-container">
        <span className="card-vehicle__price-from">Por apenas</span>
        <span className="card-vehicle__price-value">{price}</span>
      </div>
    )
  }

  return (
    <footer className="card-vehicle__footer">
      {price !== 'R$ 0' ? <Price /> : <div style={{ height: '52px' }}></div>}

      <a
        href={url || `/veiculos/${slug}`}
        className={clsx('btn w-100 card-vehicle__cta', {
          'card-vehicle__cta--unlock': vehicle_locked,
        })}
      >
        <span className="card-vehicle__text-cta-unlock">
          <i className="icon icon-lock-open-solid card-vehicle__icon-unlock mr-2" />
          Liberar desconto
        </span>
        <span className="card-vehicle__text-cta-not-unlock">
          Tenho interesse
        </span>
      </a>
    </footer>
  )
}

Footer.propTypes = {
  unlock: PropTypes.bool,
}

export default Footer
