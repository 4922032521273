import React from 'react'
import Card from './Card'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SwiperButton } from '../SwiperSlideButtons'
import { Navigation } from 'swiper'

interface CarouselProps {
  items: {
    description: string
    id: string
    number: string
    title: string
  }[]
}

const Carousel = ({ items }: CarouselProps) => {
  return (
    <div className="advantages-section-swiper-container">
      <SwiperButton id="advantage-section-prev-button">
        <ChevronLeft strokeWidth={2} />
      </SwiperButton>
      <Swiper
        centerInsufficientSlides={true}
        className="swiper-container"
        grabCursor={true}
        modules={[Navigation]}
        navigation={{
          disabledClass: 'swiper-button--disabled',
          nextEl: '#advantage-section-prev-button',
          prevEl: '#advantage-section-next-button',
        }}
        preventClicks={true}
        slidesPerView="auto"
        spaceBetween={16}
        speed={200}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index} className="swiper-slide-container">
            <Card {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
      <SwiperButton id="advantage-section-next-button">
        <ChevronRight strokeWidth={2} />
      </SwiperButton>
    </div>
  )
}

export default Carousel
