import React from 'react'

const Card = ({ id, number, title, description }) => {
  return (
    <div className="section-advantages__card">
      <p className="section-advantages__card_number">{number}</p>

      <div className="section-advantages__card_content">
        <h5 className="section-advantages__card_title">{title}</h5>
        <p className="section-advantages__card_description">{description}</p>
      </div>
    </div>
  )
}

export default Card
