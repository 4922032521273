import React, { useEffect, useMemo } from 'react'
import Footer from './Footer'
import Badge from './Badge'
import IconShielded from './IconShielded'
import IconPremium from './IconPremium'
import IconCertifiedDekra from './IconCertifiedDekra'
import placeholder from '../../../images/placeholder_acm.png'
import { numberToKm } from '../../utils/formatters'
import initLazyLoading from '../../utils/lazyLoading'

const CardVehicle = (props) => {
  const {
    className,
    slug,
    id,
    thumb,
    shielded,
    premium,
    certifiedDekra,
    manufacture_year,
    model_year,
    km,
    name,
    version,
    unlock,
    price,
    purchase_price,
    unlocked_user,
    url = '',
    card_photo_link = '',
    card_cta_link = '',
    location,
    position,
  } = props
  const cardPhotoLink = card_photo_link || (url, [card_photo_link, url])
  const cardCtaLink = card_cta_link || (url, [card_cta_link, url])
  const localLink = `/veiculos/${slug}`

  const vehicle_has_discount = unlock && price !== purchase_price

  useEffect(() => {
    initLazyLoading()
  }, [])

  return (
    <div className={`card-vehicle ${className}`}>
      <a
        data-cy={`oferta-${position + 1}`}
        className="card-vehicle__header img-lazy"
        data-src={thumb || placeholder}
        href={cardPhotoLink || localLink}
      >
        {vehicle_has_discount && <Badge>Oferta Especial</Badge>}

        <div className="card-vehicle__icons">
          {shielded && <IconShielded title="Veículo Blindado" />}
          {premium && <IconPremium title="Veículo Premium" />}
          {certifiedDekra && <IconCertifiedDekra title="Certificado Dekra" />}
        </div>
      </a>

      <div className="card-vehicle__container-info">
        {location && (
          <p className="card-vehicle__location">
            <i className="icon icon-map-pin" /> {location}
          </p>
        )}

        <h3 className="card-vehicle__title" data-match-height="title">
          {name}
        </h3>
        <p className="card-vehicle__subtitle" data-match-height="subtitle">
          {version}
        </p>

        <div className="card-vehicle__extra">
          <span>
            {manufacture_year} / {model_year}
          </span>
          <span>{numberToKm(km)} KM</span>
        </div>
      </div>

      <Footer
        id={id}
        unlock={unlock}
        price={price}
        purchase_price={purchase_price}
        unlocked_user={unlocked_user}
        slug={slug}
        url={cardCtaLink || localLink}
      />
    </div>
  )
}

export default (props) => <CardVehicle {...props} />
