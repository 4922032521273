import React, {useCallback, useState} from 'react'
import FilterMain from '../FilterMain'
import isMobile from 'is-mobile'

const FilterMainApp = (props) => {
  const {
    redirect,
    redirectAdvance,
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [q, setTextSearch] = useState('')

  const handleTextSearchChange = useCallback((e) => {
    setTextSearch(e.target.value)
  }, [])

  const getRedirectUrl = useCallback((redirectPath) => {
    const params = {}

    params['vehicle_type[]'] = 'Carro'
    if (q) params['search'] = q

    const urlSearchParams = new URLSearchParams(params)
    const { origin } = window.location
    const url = new URL(`${origin}${redirectPath}`)

    url.search = urlSearchParams.toString()

    return url
  }, [q])

  const handleSubmit = useCallback((redirect) => (e) => {
    e.preventDefault()
    setIsLoading(true)
    const url = getRedirectUrl(redirect)
    window.location = url.href
  }, [getRedirectUrl])

  return (
    <FilterMain
      isLoading={isLoading}
      isMobile={isMobile()}
      textSearch={q}
      onTextSearchChange={handleTextSearchChange}
      onSubmit={handleSubmit(redirect)}
      onSubmitAdvance={handleSubmit(redirectAdvance)}
    />
  )
}

export default FilterMainApp
