import React from 'react'

export const Card = ({ title, message, linkTitle, linkAddress, className }) => {
  return (
    <div className={`advantage-card ${className}`}>
      <header>
        <h2 className="advantage-card__title">{title}</h2>
      </header>
      <section className="advantage-card__content">{message}</section>
      <footer>
        <a className="advantage-card__link" href={linkAddress}>
          {linkTitle}
          <i
            icon-name="arrow-right"
            className="icon button__icon advantage-card__link-icon"
          ></i>
        </a>
      </footer>
    </div>
  )
}
