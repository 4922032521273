import { formatWithOptions } from 'date-fns/fp'
import { ptBR } from 'date-fns/locale'

const iuguInvoiceDateFormat = "d 'de' MMM. 'às' H'h'mm"

export const formatIuguInvoiceDateTime = (isoDate: string): string =>
  !isoDate
    ? '-'
    : formatWithOptions(
        {
          locale: ptBR,
        },
        iuguInvoiceDateFormat
      )(new Date(isoDate))

export const numberToKm = (number) => {
  if (number === null) return
  number = number.toString()
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

/**
 * Obtém um formatador padrão de moeda que pode receber options a serem
 * mescladas.
 */
export const getCurrencyFormatter = (options = {}) =>
  new Intl.NumberFormat('pt-BR', {
    ...{
      style: 'currency',
      currency: 'BRL',
    },
    ...options,
  })
