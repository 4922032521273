import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const IconShielded = ({ title }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip className="card-vehicle__tooltip">{ title }</Tooltip>}
    >
      <i className="icon icon-shield-alt-solid card-vehicle__icon card-vehicle__icon--primary" />
    </OverlayTrigger>
  )
}

export default IconShielded
